import axios from "axios";

const clientApiService = axios.create({
    baseURL: "https://my-json-server.typicode.com/syntraprogrammeurs/fakesjon",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});
export default {
    getEvents() {
        return clientApiService.get("/events");
    },
    getEvent(id) {
        return clientApiService.get("/events/" + id);
    }
};
